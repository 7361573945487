








































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import ListOfAssetsStateSelect from './ListOfAssetsStateSelect.vue';
import { State } from '../types';

@Component({
  components: {
    ListOfAssetsStateSelect
  }
})
export default class ListSearchDialog extends Vue {
  @Prop({ type: String }) search?: string;
  @Prop({ type: String }) state!: State | null;

  dialog: boolean = false;

  iSearch: string | undefined = '';
  iState: State | null = null;

  @Watch('search', { immediate: true })
  onSearch(search: string | undefined) {
    this.iSearch = search;
  }

  @Watch('state', { immediate: true })
  onState(state: State | null) {
    this.iState = state;
  }

  submit() {
    this.$emit('update:search', this.iSearch);
    this.$emit('update:state', this.iState);
    this.dialog = false;
  }

  reset() {
    this.iSearch = this.search;
    this.iState = this.state;
    this.dialog = false;
  }
}
